<template lang="pug" src="./Template.pug"></template>

<script>
// import { hydrateWhenVisible } from 'vue-lazy-hydration'
import { menuOpenDelay, menuCloseDelay } from '~~/utils/definitions/defaults.js'

export default {
  name: 'MainMenuDesktopComponent',

  // components: {
  //   dyo: hydrateWhenVisible(() => import('./Views/dyo')),
  //   simple: hydrateWhenVisible(() => import('./Views/simple'))
  // },

  props: {
    items: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    dropdownType: '',
    timeoutOpen: null,
    timeoutClose: null
  }),

  computed: {
    dyoItem() {
      return this.items.find((item) => item.view === 'dyo') || {}
    }
  },

  mounted() {
    this.$root.$on('routeChanged', this.removeDropdownType)
  },

  methods: {
    collapseHandler() {
      this.removeDropdownType()
    },

    // on tablets this event emits twice
    setDropdownType(type) {
      if (this.timeoutClose) clearTimeout(this.timeoutClose)
      this.timeoutOpen = setTimeout(() => {
        this.dropdownType = type
      }, menuOpenDelay)
    },

    removeDropdownType() {
      if (this.timeoutOpen) clearTimeout(this.timeoutOpen)
      this.timeoutClose = setTimeout(() => {
        this.dropdownType = ''
      }, menuCloseDelay)
    }
  }
}
</script>

<style lang="sass" scoped>
.main-menu
  &-desktop
    justify-content: center
    align-items: center
    position: relative
    .dropdown
      position: absolute
      top: 52px
      width: 100%
      left: 0
      right: 0
      z-index: 998
      &.rings
        .simple-view:after
          background-size: 150%
          background-position: bottom left
      &.settings
        left: -50px
        +touch
          left: -255px
        .simple-view:after
          background-size: 150%
          background-position: bottom right
      &.stones
        +touch
          left: -140px
      &.origin
        .simple-view:after
          background-size: 220%
          background-position-x: 25%
          background-position-y: 50%
        +touch
          left: -200px
      &.jewelries
        .simple-view:after
          background-size: 150%
          background-position-x: 25%
          background-position-y: 50%
        +touch
          left: -140px
      &.faq
        left: -50px
        +touch
          left: -205px
        .simple-view:after
          background-size: 220%
          background-position-x: 25%
          background-position-y: 50%
      &.about
        left: -20px
        +touch
          left: -245px
        .simple-view:after
          background-size: 170%
          background-position-x: 25%
          background-position-y: 30%
      &.education
        right: 125px
        left: unset
        +touch
          right: 325px
        .simple-view:after
          background-size: 200%
          background-position-x: 45%
          background-position-y: 70%
  &-root + &-root
    margin-left: 35px
    +touch
      margin-left: 15px
  &-root
    cursor: default
    height: 35px
    align-items: center
    &.simple
      position: relative
    .dropdown
      display: none
      ::v-deep
        .simple-view
          &.rings
            width: 520px
          &.jewelries
            width: 520px
          &.stones
            width: 520px
          &.origin
            width: 520px
          &.settings
            width: 520px
          &.faq
            width: 400px
          &.about
            width: 400px
          &.education
            width: 400px
    &-link
      position: relative
      align-items: center
      height: 100%
      &__line-decoration
        position: absolute
        bottom: 0
        height: 4px
        width: 100%
        background: $primary-color
        opacity: 0
        transition: opacity .3s ease-in
    .arrow-wrapper
      display: none
      overflow: hidden
      position: absolute
      height: 17px
      width: 100%
      top: 35px
      z-index: 999
      .arrow
        height: 18px
        width: 18px
        border-right: 0
        border-bottom: 0
        -moz-transform: rotate(45deg)
        -ms-transform: rotate(45deg)
        -webkit-transform: rotate(45deg)
        transform: rotate(45deg)
        background: $secondary-bg-color
        content: ''
        display: block
        @include box-shadow(0 0 5px 0, rgba(0,0,0,0.2))
        position: absolute
        margin: 0 auto
        left: 0
        right: 0
        top: 8px
    .main-menu-item-menu
      text-transform: uppercase
      font-weight: 600
      font-size: 14px
      white-space: nowrap
      transition: all .3s ease-in
      //+touch
      //  font-size: 12px
      @media (max-width: 890px)
        font-size: 13px
    &:not(.hide)
      .dropdown
        display: block
      .arrow-wrapper
        display: block
      .main-menu-root-link__line-decoration
        opacity: 1
      .main-menu-item-menu
        font-weight: bold
</style>
