<template lang="pug" src="./Template.pug"></template>

<script>
import { hydrateWhenVisible } from 'vue-lazy-hydration'
import MainMenuDesktop from '~~/components/Layout/MainMenu/MainMenuDesktop/MainMenuDesktop'

export default {
  name: 'MainMenuDesktopComponent',

  components: {
    dyo: hydrateWhenVisible(() => import('./Views/dyo')),
    simple: hydrateWhenVisible(() => import('./Views/simple')),
    rings: hydrateWhenVisible(() => import('./Views/rings.vue')),
    stones: hydrateWhenVisible(() => import('./Views/stones.vue')),
    settings: hydrateWhenVisible(() => import('./Views/settings.vue')),
    jewelry: hydrateWhenVisible(() => import('./Views/jewelry.vue'))
  },

  extends: MainMenuDesktop
}
</script>

<style lang="sass" scoped>
.main-menu
  &-desktop
    justify-content: center
    align-items: center
    position: relative
    .dropdown
      position: absolute
      top: 35px
      width: 100%
      left: 0
      right: 0
      z-index: 998
      &.full-width
        &::before
          content: ''
          position: absolute
          top: -1px
          left: 50%
          transform: translateX(-50%)
          height: calc(100% + 1px)
          width: 100vw
          background: #ffffff
          z-index: -1
      .close-button
        position: absolute
        width: 100vw
        left: 50%
        transform: translateX(-50%)
        top: 20px
        z-index: 1
        & svg
          width: 16px
          height: 16px
          position: absolute
          right: 30px
          cursor: pointer
      &.rings
        .simple-view:after
          background-size: 150%
          background-position: bottom left
      &.faq
        left: -50px
        +touch
          left: -205px
      &.about
        left: -20px
        +touch
          left: -245px
      &.education
        right: 125px
        left: unset
        +touch
          right: 325px
  &-root + &-root
    margin-left: 35px
    +touch
      margin-left: 15px
  &-root
    cursor: default
    height: 35px
    align-items: center
    &.simple
      position: relative
    .dropdown
      display: none
      ::v-deep
        .simple-view
          &.faq
            width: 400px
          &.about
            width: 400px
          &.education
            width: 400px
    &-link
      position: relative
      align-items: center
      height: 100%
      &__line-decoration
        position: absolute
        bottom: 0
        height: 4px
        width: 100%
        background: $primary-color
        opacity: 0
        transition: opacity .3s ease-in
    .main-menu-item-menu
      text-transform: uppercase
      font-weight: 600
      font-size: 14px
      white-space: nowrap
      transition: all .3s ease-in
      //+touch
      //  font-size: 12px
      @media (max-width: 890px)
        font-size: 13px
    &:not(.hide)
      .dropdown
        display: block
      .main-menu-root-link__line-decoration
        opacity: 1
      .main-menu-item-menu
        font-weight: bold
.is-pinned
  .main-menu-desktop
    .dropdown
      &.stones,
      &.settings
        &::before
          //border-top: none
      &.faq,
      &.education
        top: 34px
</style>
